.feedback-container {
    background-color: #1e2130;
    color: white;
    padding: 20px;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    margin-top: 40px;
  }
  
  .feedback-content {
    display: flex;
    justify-content: space-between;
  }
  
  .feedback-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    flex: 3;
  }
  
  .feedback-option {
    padding: 15px;
    border-radius: 5px;
  }
  
  .feedback-option h3 {
    margin-top: 0;
  }
  
  .feedback-form {
    display: flex;
    flex-direction: column;
    background-color: #25293C;
    padding-left: 10%;
    padding-right: 10%;
    padding: 5%;
    border-radius:10px;
    flex: 1;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .feedback-form input,
  .feedback-form textarea {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #2D3248;
    border: none;
    border-radius: 5px;
    color: white;
    resize: vertical;
    margin-top: 10px;
  }
  
  .feedback-form button {
    background-color: #777D9F;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    align-self: center;
    padding-left: 20%;
    padding-right: 20%;
    margin-top: 10px;
  }
  
  .feedback-form button:hover {
    background-color: #5a5d6e;
  }

  @media screen and (max-width: 1300px) {
    .feedback-options {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      width: 100%;
    }


    .feedback-content {
      display: flex;
      flex-direction: column;
    }
}
