.editer-header-container{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}
.header-buttons-container{
  flex-direction: row;
  display: flex;
  gap: 10px;
}
.search-tools-container{
  display: flex;
  gap: 10px;
}

.newbutton{
    background-color: #F06767;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
    font-family: "Open Sans", sans-serif;
   
}
.copybutton{
    border-width: 1px;
    border-color: #F06767;
    padding-top: 5px;
    background-color: transparent;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    border-style: solid;
    font-family: "Open Sans", sans-serif;

}

.search-container{
  border-color: #434968;
  border-width: 1px;
  border-radius: 4px;
  outline: none;
  border-style: solid;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.search-input {
    padding: 10px ;
    font-size: 16px;
    color: #fff;
    background-color: transparent;
    outline: none;
    border: none;
    width: 300px
  }
  
  .search-input::placeholder {
    color: #B6BEE3;
  }

  .upButton {
    background-color: #393E58;
    border: none;
    border-radius: 5px;
    padding-right: 20px;
    padding-left: 20px;

  }

  .downButton {
    background-color: #393E58;
    border: none;
    border-radius: 5px;
    padding-right: 20px;
    padding-left: 20px;
  }
  
  @media screen and (max-width: 1115px) {
    .editer-header-container{
      flex-wrap: wrap;
    }
    .search-tools-container{
      margin-top: 10px;
      flex-wrap: wrap;
    }
    .search-input {
      width: 90%;
    }
  }

 