.button_minus {
    width: 18px;
    height: 18px;
    background: #ffffff00;
    cursor: pointer;
    border: 1px solid #fafafa;
    position: relative;
    border-radius: 20%;
    margin: 10px;
  }
  
  .button_minus:after {
    content: '';
    position: absolute;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 2px;
    background: #f1f1f1;
    top: 50%;
    left: 50%;
  }
  
  .button_minus:hover:before,
  .button_minus:hover:after {
    background: #fff;
    transition: 0.2s;
  }
  
  .button_minus:hover {
    background-color: #F06767;
    transition: 0.2s;
  }