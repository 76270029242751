body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  background-color: #25293C;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #333953;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #777D9F;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}