.app-title{
  color: #F06767;
  font-style: normal;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding-left: 2%;
  padding-right: 2%;
}

.main{
  background-color: #25293C;
}