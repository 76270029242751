.button_plus {
    width: 18px;
    height: 18px;
    background: transparent;
    cursor: pointer;
    border: 1px solid #ffffff;
    position: relative;
    border-radius: 20%;
    margin: 10px;
  }
  
  .button_plus:after {
    content: '';
    position: absolute;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 2px;
    background: #ffffff;
    top: 50%;
    left: 50%;
  }
  
  .button_plus:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    height: 7px;
    width: 2px;
  }
  
  .button_plus:hover:before,
  .button_plus:hover:after {
    background: #ffffff;
    transition: 0.2s;
  }
  
  .button_plus:hover {
    background-color: #F06767;
    transition: 0.2s;
  }