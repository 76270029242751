h1{
    color: #FFFFFF;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 22px;
}
.ul-1 {
  list-style-type: none;
  padding: 0;
}
.li-1 {
  margin: 10px 0;
  padding: 10px;
  background: #1e2130;
  color:  #B6BEE3;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
ul{
  padding-left: 20px;
}
li {
  padding-top: 5px;
  padding-left: 0px;
  color:  #B6BEE3;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  margin: 0;
}
li span {
  font-weight: bold;
}
p{
    color: #B6BEE3;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.6;
    margin: 0;
    font-family: "Open Sans", sans-serif;
    
}

.main-containter{
    background-color: #25293C;
    padding-left: 2%;
    padding-right: 2%;
}

.intro-container{
    display: flex;
    flex-direction: column;
}

.feature-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  
}
.feature-content-container{
    display: flex;
    flex-direction: column;
    width: 30%;
}


.donate-button {
    background-color: rgb(88 167 120);
    color: white;
    border: none;
    height: 55px;
    font-size: 16px;
    border-radius: 5px;
    width: 60%;
  }

  .donate-button-container{
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
  }
  .dontate-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background-color: #1e2130;
    flex-direction: row;
    padding: 40px;
    margin-top: 40px;
    font-family: Arial, sans-serif;
    margin-bottom: 50px;
  }

  .dontate-content-container{
    display: flex;
    flex-direction: column;
    width: 60%;
  }
  
  .formater-container{
    display: flex;
    flex-direction: row;
  }
  .container-editer-main{
    width: 100%;
  }
  .leftads{
      width: 0%;
      margin-right: 10px;
  }
  .rightads{
    width: 0%;
    margin-left: 10px;
  }
  .feature-xml-container {
    display: grid;
    gap: 5px; 
    grid-template-columns: 25% 25% 25% 25%;
  }
  
@media screen and (max-width: 1000px) {
    .feature-content-container{
        width: 100%;
    }
    .dontate-content-container{
        width: 100%;
      }
      .donate-button-container{
        width: 100%;
      }
      .dontate-container {
        flex-direction: column;
      }
      .feature-xml-container {
        display: grid;
        gap: 16px; 
        grid-template-columns: 50% 50%;
      }

}

@media screen and (max-width: 600px) {
 
    .feature-xml-container {
      display: grid;
      gap: 16px; 
      grid-template-columns: auto;
    }

}