.dropdown-container {
  display: flex;
  align-items: center;
  border: 1px solid #434968;
  border-radius: 4px;
  background-color: #2F3349;
  overflow: hidden;
  position: relative;
}

.format-dropdown-select {
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  color: white;
  background-color: transparent;
  padding: 10px 30px 10px 30px;
  appearance: none;
  cursor: pointer;

}

.format-dropdown-select option {
  background-color: #25293C;
  border-color: #1b1c20;
  border-width: 1px;
  border-style: solid;
  outline: none;
  border-style: solid;
  appearance: none;
}