.toolbar-container{
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #2F3349;
}
.error-text { 
  font-size: 16px; 
  color: rgb(255, 106, 60); 
}
.container-editor {
    background-color: #2F3349;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.header-line {
    background-color: #434968;
    height: 1px;
    width: 100%;
}

.textareainput {

    height: 200px;
    padding: 10px;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-top: 40px;
    margin-left: 20px;
    margin-bottom: 20px;
    border: 1px solid #434968;
    border-radius: 5px;
    resize: none;
    color: white;
    font-size: 18px;
    background-color: #25293C;
    border-color: #434968;
  }

  .textareainput[type=text]:focus {
    border-style: solid;
    border: 3px solid #777D9F;
    outline: #777D9F;
  }

  .buttons-formats-container{
    display: flex;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  .buttonClear {
    padding: 10px 20px;
    background-color: #138488;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5px;
    transition: background-color 0.3s;
    margin-left: 5px;
    font-weight: 1000;
    font-family: "Open Sans", sans-serif;
  }
  
  .buttonFormat {
    padding: 10px 20px;
    background-color: rgb(230, 222, 122);
    color: #25293C;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 1000;
    font-family: "Open Sans", sans-serif;
  }

  .container-formatted-text{
    
    padding: 10px;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-top: 40px;
    margin-left: 20px;
    margin-bottom: 20px;
    border: 1px solid #434968;
    border-radius: 5px;
    color: white;
    font-size: 18px;
    background-color: #25293C;
    border-color: #434968;
    overflow: scroll;
  }